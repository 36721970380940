import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LocationDetail } from '../../../models/Location';
import { MapGoogle } from '../../../components/MapGoogle';
import "./LocationDetail.css"
import { AccessibilityContext } from '../../../contexts/AccessibilityContext';
import axios from 'axios';
import { Audio } from "./Audio";
import data from "../../../json/sound.json";
import { LocationContext } from '../../../contexts/LocationContext';
import { useTranslation } from 'react-i18next';

interface Sound {
    voicefile: string;
    tonebef: Tone;
    toneaft: Tone;
}

interface Tone {
    file: string;
    count: number;
    volume: number;
    comment: string;
}

const mockData: LocationDetail = {
    id: "1",
    name: "Example Location",
    city: "Sample City",
    street: "Main Street",
    number: "123",
    loclat: "52.2296756",
    loclon: "21.0122287",
    subname: "Sample Subname",
    content: "<profile1>Profile 1 Content</profile1><profile12>Profile 2 Content</profile12><profile3>Profile 3 Content</profile3><p>This is a general description of the location.</p>",
    country: "",
    searchkeys: "",
    share: 1,
    state: "",
    title: "",
    voiceaddr: "fd"
};


const profileLabels: { [key: number]: string } = {
    1: "Problem z widzeniem",
    2: "Problem ze słyszeniem",
    3: "Problem z barierami architektonicznymi",
    4: "Potrzeba tekstu uproszczonego",
    5: "Brak szczególnych potrzeb"
};


export const LocationDetails = () => {
    let { id } = useParams();
    const [location, setLocation] = useState<LocationDetail | null>();
    const [loading, setLoading] = useState<boolean>(true);
    const { isContrast } = useContext(AccessibilityContext);
    const [mapSize, setMapSize] = useState({ width: '1000px', height: '600px' });
    const [tonePath, setTonePath] = useState<string>("");
    const { locations } = useContext(LocationContext);
    const { t, i18n } = useTranslation();
    const [profiles, setProfiles] = useState<number[]>([]);
    const [selectedProfile, setSelectedProfile] = useState<string>("0");
    const headerRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.focus();
            }
        }, (1000));
    }, [location]);
    useEffect(() => {
        console.log(i18n.language)
    }, [i18n.language])

    useEffect(() => {
        if (id === null) return;
        axios.get(process.env.REACT_APP_SERVER_BE_IP + `zarzadzanie/gettagdatabase.php?locbrowser=1&id=${id}&langpri=${i18n.language}`).then((response) => {
            const firstValue: any = Object.values(response.data)[0];
            const secondValue: any = Object.values(response.data)[1];
            if (response.data.length == 0 || response.data == undefined) {
                setLoading(false);
                return;
            }
            if (typeof firstValue === 'object' && Object.keys(firstValue).length !== 0) {
                firstValue.content = i18n.language == "en" ? firstValue.content2 != "" ? firstValue.content2 : firstValue.content1 : firstValue.content1;
                firstValue.voicefile = firstValue.voicefile1;
                extractProfiles(firstValue.content);
                setLocation(firstValue as LocationDetail);
            } else {
                setLocation(null);
            }
            if (typeof secondValue === 'object' && Object.keys(secondValue).length !== 0) {
                setTonePath(secondValue);
            }
        }).catch(error => {
            console.error('Error fetching location data:', error);
            setLocation(null);
            setLoading(false);
        });

    }, [id, i18n.language]);

    const processContentLinks = (content: string, basePath: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const links = doc.querySelectorAll('a[href^="content?name="]');

        links.forEach(link => {
            const href = link.getAttribute('href');
            if (href) {
                const fullUrl = new URL(href, window.location.origin);
                const nameParam = new URLSearchParams(fullUrl.search).get('name');
                if (nameParam) {
                    link.setAttribute('href', `${basePath}/${nameParam}`);
                }
            }
        });

        return doc.body.innerHTML;
    };

    const disableFormButtons = (content: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const forms = doc.querySelectorAll('form[action*="activate"]');

        forms.forEach(form => {
            const buttons = form.querySelectorAll('button[type="submit"]');
            buttons.forEach(button => {
                button.setAttribute('disabled', 'true');
            });
        });

        return doc.body.innerHTML;
    };



    const extractProfiles = (content: string) => {
        const profileRegex = /<profile(\d+)>/g;
        const profilesFound = new Set<number>();
        let match;
        while ((match = profileRegex.exec(content)) !== null) {

            const profileNumber = parseInt(match[1], 10);

            const digits = match[1].split('');
            digits.forEach(digit => {
                const mainProfileNumber = parseInt(digit, 10);
                console.log(mainProfileNumber);
                profilesFound.add(mainProfileNumber);
            });
        }
        setProfiles(Array.from(profilesFound).sort((a, b) => a - b));
    };

    const handleProfileChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.target.value);
    };

    const getUpdatedContent = () => {
        if (!location || selectedProfile === null) return '';
        let updatedContent = '';
        const profileRegex = new RegExp(`<profile(\\d+)>.*?</profile\\1>`, 'gs');
        let match;
        while ((match = profileRegex.exec(location.content)) !== null) {
            const profileNumber = parseInt(match[1], 10);
            if (selectedProfile == "") break;
            if (profileNumber.toString().includes(selectedProfile.toString())) {
                updatedContent += match[0];
            }
        }
        const generalDescription = location.content.replace(/<profile\d+>.*?<\/profile\d+>/g, '');
        const processedContent = processContentLinks(`${updatedContent} ${generalDescription}`, `/lokalizacje/${id}`);
        const contentWithDisabledButtons = disableFormButtons(processedContent);
        return contentWithDisabledButtons;
    };


    useEffect(() => {
        if (location != null)
            setLoading(false);
    }, [location])

    useEffect(() => {
        document.title = "Szczegóły znacznika - TOTUPOINT"
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setMapSize({ width: (window.innerWidth - 50) + "px", height: '400px' });
            } else {
                setMapSize({ width: '452px', height: '452px' });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatAddress = (
        city?: string,
        street?: string,
        streetNumber?: string
    ): string => {
        let address = '';

        if (city && city.trim() !== '') {
            address += city;
        }

        if (street && street.trim() !== '') {
            if (city && city.trim() !== '') {
                address += ', ';
            }
            address += street;
        }

        if (streetNumber && streetNumber.trim() !== '') {
            if (address !== '') {
                address += ' ';
            }
            address += streetNumber;
        }

        return address;
    };


    if (loading) return <span>{t("Trwa ładowanie znacznika, proszę czekać...")}</span>;
    if (location == null) return <span>{t("Nie odnaleźliśmy znacznika o id")} {id}</span>;

    const streetNumber = location.number?.split("#")[0] ?? '';

    return (
        <section className='md:w-[900px] md:max-w-max max-w-sm mx-auto md:mb-[200px] mb-[100px] mt-[20px]'>
            <h1 ref={headerRef} role="heading" aria-level={1} tabIndex={-1} className={(isContrast ? "text-yellow-400" : " text-black") + "  text-center text-custom-40px font-bold font-['Roboto'] leading-[47.41px] flex flex-row justify-center mb-[40px]"}>
                {location.name} - {location.city}
            </h1>
            <div className='mb-[51px] md:mx-0 mx-auto flex md:hidden justify-center'>
                <MapGoogle height={mapSize.height} width={mapSize.width} center={{ lat: Number(location.loclat), lng: Number(location.loclon) }} zoom={15} locationsFiltered={locations} />
            </div>
            <div className='flex md:flex-row flex-col justify-between md:mx-0 mx-[7px] gap-10'>
                <div className=''>
                    <h2 className={(isContrast ? "text-yellow-400" : " text-black") + " text-center text-custom-4xl font-semibold font-['Roboto'] leading-[42.67px]"}>
                        {location.subname}
                    </h2>
                    <div className={(isContrast ? "text-yellow-400" : " text-black") + " text-center text-custom-xl font-light font-['Roboto'] italic"}>
                        {formatAddress(location.city, location.street, streetNumber)} {location.name ? "- " + location.name : ""}

                    </div>
                    <div className="md:w-[472px] flex flex-col gap-[20px] mt-[10px]">
                        <div className='flex flex-col gap-[10px]'>
                            <span className={(isContrast ? "text-red-600" : " text-cyan-700 ") + " text-custom-xl font-normal font-['Roboto']"}>
                                {t("Komunikat wyświetlany przez aplikację na urządzeniu mobilnym Użytkowników:")}
                            </span>
                            {profiles.length > 0 && (
                                <div className="mt-4">
                                    <label htmlFor="profileSelect" className={(isContrast ? "text-yellow-400" : " text-black") + " font-normal font-['Roboto']"}>
                                        {t("Wybierz profil:")}
                                    </label>
                                    <select id="profileSelect" value={selectedProfile || ''} onChange={handleProfileChange} className="ml-2">
                                        <option value="">{t("Wybierz profil")}</option>
                                        {profiles.map(profile => (
                                            <option key={profile} value={profile.toString()}>{t(profileLabels[profile])}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <blockquote className={(isContrast ? "text-yellow-400" : "text-black") + " font-normal font-['Roboto']"}>
                                <div className="text-3xl text-g text-gray-400 text-left leading-tight h-3">“</div>
                                <div className={(isContrast ? "text-yellow-400" : "text-gray-800") + ' content text-custom'} dangerouslySetInnerHTML={{ __html: getUpdatedContent() }}></div>
                                <div className="text-3xl text-gray-400 text-right leading-tight h-3 -mt-3">”</div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:mt-0 mt-10'>
                    <div className='mb-[51px] md:mx-0 mx-auto md:flex hidden' aria-label={t("Interaktywna mapa google - lokalizacja naszych znaczników")}>
                        <MapGoogle height={mapSize.height} width={mapSize.width} center={{ lat: Number(location.loclat), lng: Number(location.loclon) }} zoom={15} locationsFiltered={locations} />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " mb-2 text-center font-bold"}>{t("Dźwięk znacznika")}</h2>
                        <Audio location={location} tonePathUrl={tonePath} />
                    </div>
                </div>
            </div>
        </section>
    );
};