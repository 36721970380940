import { useContext, useState } from "react";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../contexts/LocationContext";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import { pl } from "date-fns/locale";
import { ReactComponent as Pinezka } from "../../../assets/icons/ToTuPinezka.svg";
import { ReactComponent as Urzadzenie } from "../../../assets/icons/ToTuUrzadzenie.svg";
import { ReactComponent as Budynek } from "../../../assets/icons/TuTuBudynek.svg";
import abc from "../../../assets/icons/TuTuBudynek.svg";


interface LastInstalledDevice {
    id: string;
    index: number;
    name: string;
    address: string;
    length: number;
    date: string;
}

export const LatestDevices = () => {
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();
    const { locations } = useContext(LocationContext);
    const [filterDate, setFilterDate] = useState<Date | null>(new Date());


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const pagesToShow = 5;
    registerLocale("pl", pl);


    const groupAndCountDevices = (): LastInstalledDevice[] => {
        const groupedData: Record<string, { name: string, count: number, id?: string }> = {};


        const parseDate = (dateString: string): Date | null => {
            const trimmedDate = dateString.trim();

            let dateObject = new Date(trimmedDate);
            if (!isNaN(dateObject.getTime())) {
                return dateObject;
            }

            const europeanDateMatch = trimmedDate.match(/^(\d{2})[-.](\d{2})[-.](\d{4})$/);
            if (europeanDateMatch) {
                const [_, day, month, year] = europeanDateMatch;
                dateObject = new Date(`${year}-${month}-${day}`);
                if (!isNaN(dateObject.getTime())) {
                    return dateObject;
                }
            }

            const yearMatch = trimmedDate.match(/^\d{4}$/);
            if (yearMatch) {
                dateObject = new Date(`${trimmedDate}-01-01`);
                if (!isNaN(dateObject.getTime())) {
                    return dateObject;
                }
            }

            return null;
        };


        for (const key in locations) {
            const device = locations[key];
            const { name, date_of_installation, street, number, city, country, id } = device;

            if (!name || !date_of_installation) continue;

            const dateObject = parseDate(date_of_installation);
            const formattedDate = dateObject ? dateObject.toISOString().split('T')[0] : "Invalid Date";
            const address = `${street} ${number}, ${city}, ${country}`;
            const groupKey = `${address}_${formattedDate}`;

            if (!groupedData[groupKey]) {
                groupedData[groupKey] = {
                    name,
                    count: 0,
                    id
                };
            }

            groupedData[groupKey].count += 1;
        }

        const result: LastInstalledDevice[] = Object.entries(groupedData).map(([key, { name, count, id }], index) => {
            const [address, date] = key.split('_');
            return {
                id: id ?? "",
                index: 0,
                name,
                address,
                length: count,
                date,
            };
        });

        const filteredLocations = result.filter((location) => {
            const dateObject = parseDate(location.date ?? "");
            if (filterDate) {
                return dateObject && new Date(dateObject) <= new Date(filterDate);
            }
            return true;
        });


        var i = 1;

        return filteredLocations.sort((a, b) => {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();

            if (isNaN(dateA)) return 1;
            if (isNaN(dateB)) return -1;

            return dateB - dateA;
        }).map(a => { if (a.date.toLowerCase() == "lubin") console.log(a); return a }).filter(r => r.date != "Invalid Date" && r.date.toLowerCase() != "lubin")
            .map((result: LastInstalledDevice) => {
                result.index = i++
                return result
            })
    };



    const handleFilterDateChange = (date: Date | null) => {
        setFilterDate(date);
        setCurrentPage(1);
    };

    const devices = groupAndCountDevices();
    const totalPages = Math.ceil(devices.length / itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage >= totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const paginatedDevices = devices.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const getInstalationLabel = (count: number) => {
        if (count === 1) return 'instalacja';
        if (count >= 2 && count <= 4) return 'instalacje';
        return 'instalacji';
    };

    return (
        <section className="flex flex-col gap-20 justify-center mb-[70px] mt-[200px]">
            <div className="md:mx-auto md:w-full">
                <div
                    className={
                        (isContrast ? "text-yellow-400" : " text-black ") +
                        " text-custom-40px font-bold leading-[47.41px] text-center md:mb-[100px] mb-[50px]"

                    }
                >
                    <h2
                        role="heading"
                        aria-level={2}
                        className="font-extrabold"
                    >
                        Ostatnie instalacje
                    </h2>
                </div>
                <div className="bg-white rounded-2xl shadow p-4 flex flex-col gap-1 px-4 py-4 md:px-[76px] md:py-[31px]">
                    <div className="flex flex-col md:flex-row justify-end">
                        <div className="flex flex-col md:flex-row justify-between items-center mb-[10px] gap-5">
                            <label htmlFor="filter-date" className="text-sm text-[#585858]">
                                {t('Pokaż instalacje do daty:')}
                            </label>
                            <DatePicker
                                selected={filterDate}
                                onChange={handleFilterDateChange}
                                className="border rounded-lg text-left pl-3 mt-0.5 h-[29px] w-full md:w-[160px]"
                                placeholderText="Wybierz datę"
                                dateFormat={"dd.MM.yyyy"}
                                locale="pl"
                                aria-required="true"
                                icon={
                                    <svg aria-label="Ikona kalendarza" className="right-0" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3333 1.6665V4.99984M5.66667 1.6665V4.99984M1.5 8.33317H16.5M3.16667 3.33317H14.8333C15.7538 3.33317 16.5 4.07936 16.5 4.99984V16.6665C16.5 17.587 15.7538 18.3332 14.8333 18.3332H3.16667C2.24619 18.3332 1.5 17.587 1.5 16.6665V4.99984C1.5 4.07936 2.24619 3.33317 3.16667 3.33317Z" stroke="#12728C" strokeOpacity="0.65" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                }
                                showIcon
                                toggleCalendarOnIconClick
                            />
                        </div>
                    </div>
                    <div className="overflow-x-auto md:overflow-visible">
                        <table className="w-full mt-[20px] table-auto" role="table" aria-label="Lista ostatnich instalacji">
                            <thead>
                                <tr className="text-[#12728C] text-sm font-medium mb-[5px]" role="row">
                                    <th className="px-2 pt-[8px] text-left max-w-[40px] min-w-[40px]">
                                        Nr
                                    </th>
                                    <th className="px-2 py-1 text-left max-w-[350px] min-w-[350px]">
                                        <div className="flex items-center">
                                            <Budynek width={20} height={20} fill="#12728C" />
                                            <span className="pl-2 pt-2">Nazwa instytucji</span>
                                        </div>
                                    </th>
                                    <th className="px-2 py-1 text-left max-w-[150px] min-w-[150px]">
                                        <div className="flex items-center">
                                            <Pinezka width={20} height={20} fill="#12728C" />
                                            <span className="pl-2 pt-2">Adres</span>
                                        </div>
                                    </th>
                                    <th className="px-2 py-1 text-left min-w-[150px]">
                                        <div className="flex items-center">
                                            <Urzadzenie width={20} height={20} fill="#12728C" stroke="none" />
                                            <span className="pl-2 pt-2">Liczba instalacji</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody role="rowgroup">
                                {paginatedDevices.map((installation) => (
                                    <tr
                                        key={installation.id}
                                        className="text-[14px] font-medium h-[50px] font-roboto border-b-[1px] border-[#EEEEEE]"
                                        role="row"
                                        aria-labelledby={`installation-${installation.id}-name installation-${installation.id}-address installation-${installation.id}-count`}
                                    >
                                        <td
                                            className="px-2 py-2 text-[#292d32] max-w-[40px] min-w-[40px]"
                                            role="cell"
                                            id={`installation-${installation.id}-index`}
                                        >
                                            {installation.index}.
                                        </td>
                                        <td
                                            className="px-2 py-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[350px] min-w-[350px]"
                                            title={installation.name}
                                            role="cell"
                                            id={`installation-${installation.id}-name`}
                                        >
                                            <div className="relative group">
                                                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap hover:text-blue-600 hover:underline">
                                                    <Link
                                                        to={`../lokalizacje/${installation.id}`}
                                                        aria-label={`Przejdź do szczegółów instalacji ${installation.name}`}
                                                    >
                                                        {installation.name}
                                                    </Link>
                                                </span>
                                            </div>
                                        </td>
                                        <td
                                            className="px-2 py-2 font-normal text-left max-w-[200px]"
                                            role="cell"
                                            id={`installation-${installation.id}-address`}
                                        >
                                            <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                                                {installation.address
                                                    .split(',')
                                                    .map((part) => part.trim())
                                                    .filter((part) => part.length > 0)
                                                    .join(', ')}
                                            </span>
                                        </td>
                                        <td
                                            className="px-2 py-2 font-normal text-left max-w-[150px] min-w-[150px]"
                                            role="cell"
                                            id={`installation-${installation.id}-count`}
                                            aria-label={`${installation.length} ${getInstalationLabel(installation.length)}`}
                                        >
                                            <span className="pl-1">
                                                {installation.length}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-center md:justify-end mt-4">
                        <div className="flex md:gap-2 gap-1 flex-wrap" role="navigation" aria-label="Paginacja">
                            <button
                                onClick={() => handlePageChange(1)}
                                className="h-[28px] md:px-2.5 px-1 py-1.5 bg-neutral-100 rounded border border-[#eeeeee] text-[#404b52] text-xs font-medium cursor-pointer"
                                style={{ userSelect: 'none' }}
                                role="button"
                                aria-label="Pierwsza strona"
                            >
                                {"<<"}
                            </button>
                            {[...Array(endPage - startPage + 1)].map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(startPage + index)}
                                    className={`h-[28px] md:px-2.5 px-1.5 py-1.5 rounded border ${currentPage === startPage + index
                                        ? "bg-[#12728c]/90 border-[#12728c] text-white"
                                        : "bg-neutral-100 border-[#eeeeee] text-[#404b52]"
                                        } text-xs font-medium cursor-pointer`}
                                    style={{ userSelect: 'none' }}
                                    role="button"
                                    aria-label={`Strona ${startPage + index}`}
                                    aria-current={currentPage === startPage + index ? "page" : undefined}
                                >
                                    {startPage + index}
                                </button>
                            ))}
                            {endPage < totalPages - 1 && (
                                <div
                                    className="h-[28px] px-1 py-1.5 text-[#404b52] text-xs font-medium"
                                    style={{ userSelect: 'none' }}
                                    aria-hidden="true"
                                >
                                    {"..."}
                                </div>
                            )}
                            {currentPage !== totalPages && (
                                <button
                                    onClick={() => handlePageChange(totalPages)}
                                    className={`h-[28px] px-2.5 py-1.5 rounded border ${currentPage === totalPages
                                        ? "bg-[#12728c]/90 border-[#12728c] text-white"
                                        : "bg-neutral-100 border-[#eeeeee] text-[#404b52]"
                                        } text-xs font-medium cursor-pointer`}
                                    style={{ userSelect: 'none' }}
                                    role="button"
                                    aria-label={`Strona ${totalPages}`}
                                >
                                    {totalPages}
                                </button>
                            )}
                            <button
                                onClick={() => handlePageChange(totalPages)}
                                className="h-[28px] md:px-2.5 px-1 py-1.5 bg-neutral-100 rounded border border-[#eeeeee] text-[#404b52] text-xs font-medium cursor-pointer"
                                style={{ userSelect: 'none' }}
                                role="button"
                                aria-label="Ostatnia strona"
                            >
                                {">>"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
