import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LocationDetail } from "../../../../models/Location";
import { useTranslation } from "react-i18next";
import { AccessibilityContext } from "../../../../contexts/AccessibilityContext";

export const LocationDetailsContent = () => {
    let { id, name } = useParams();
    const [location, setLocation] = useState<LocationDetail | null>();
    const [loading, setLoading] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const { isContrast } = useContext(AccessibilityContext);
    const headerRef = useRef<HTMLHeadingElement>(null);
    const [content, setContent] = useState<string>("");
    const [contentNotFound, setContentNotFound] = useState<boolean>(false);
    const [contentTitle, setContentTitle] = useState<string>("");


    const extractContentName = (content: string, name: string): string | null => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const link = doc.querySelector(`a[href="content?name=${name}"]`);
        if (link) {
            return link?.textContent || null;
        }
        return null;
    };

    const disableFormButtons = (content: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const forms = doc.querySelectorAll('form[action*="activate"]');

        forms.forEach(form => {
            const buttons = form.querySelectorAll('button[type="submit"]');
            buttons.forEach(button => {
                button.setAttribute('disabled', 'true');
            });
        });

        return doc.body.innerHTML;
    };


    useEffect(() => {
        if (id === null) return;
        axios.get(process.env.REACT_APP_SERVER_BE_IP + `zarzadzanie/gettagdatabase.php?locbrowser=1&id=${id}&langpri=${i18n.language}`).then((response) => {

            const firstValue: any = Object.values(response.data)[0];

            if (response.data.length === 0 || response.data === undefined) {
                setLoading(false);
                return;
            }
            if (typeof firstValue === 'object' && Object.keys(firstValue).length !== 0) {
                firstValue.content = i18n.language == "en" ? firstValue.content2 != "" ? firstValue.content2 : firstValue.content1 : firstValue.content1;
                setLocation(firstValue as LocationDetail);
                if (name && firstValue.contentname[name]) {
                    let specificContent = extractContentName(firstValue.content, name);
                    setContentTitle(specificContent ?? "")
                    let modifiedContent = firstValue.contentname[name];
                    const regex = /<a href="content">([^<]*)<\/a>/g;

                    modifiedContent = modifiedContent.replace(regex, `<a href="/lokalizacje/${id}">$1</a>`);
                    const disabledButtonWithActivate = disableFormButtons(modifiedContent)
                    setContent(disabledButtonWithActivate);
                    setContentNotFound(false);
                } else {
                    setContentNotFound(true);
                }
            } else {
                setLocation(null);
            }
        }).catch(error => {
            console.error('Error fetching location data:', error);
            setLocation(null);
            setLoading(false);
        });
    }, [id, name]);

    useEffect(() => {
        if (location != null)
            setLoading(false);

        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, [location]);

    if (loading) return <span>{t("Trwa ładowanie znacznika, proszę czekać...")}</span>;
    if (location == null) return <span>{t("Nie odnaleźliśmy znacznika o id")} {id}</span>;

    return (
        <section>
            {contentNotFound ? (
                <h1>{t("Nie znaleziono szczegółów dla znacznika dla nazwy")} "{name}"</h1>
            ) : (
                <div className="mb-[100px]">
                    <h1 ref={headerRef} aria-level={1} tabIndex={-1} className={(isContrast ? "text-yellow-400" : " text-black") + " text-center text-custom-32px font-bold font-['Roboto'] leading-[47.41px] flex flex-row justify-center mb-[40px]"}>
                        Szczegóły znacznika {location.name} - {location.city}
                    </h1>
                    <h2 className="text-md text-black font-bold">{contentTitle}</h2>
                    <div className="ml-2">
                        <span className={(isContrast ? "text-yellow-400" : "text-gray-800") + ' content text-custom'} dangerouslySetInnerHTML={{ __html: content }}></span>
                    </div>
                </div>
            )}
        </section>
    );
}
